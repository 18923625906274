import React, {useState} from "react";
import SortableTree, { toggleExpandedForAll, getFlatDataFromTree } from 'react-sortable-tree';
import { Button } from '@paljs/ui/Button';
import LoadingSpinner from "../../pages/loader";


const tree = ({treeData, setTreeData, isLoading}) => {
    const [statusMessage, setStatusMessage] = useState('')
    const [suggestedSelectedNode, setSuggestedSelectedNode] = useState();

    console.log("tree data in showcase tree ", treeData)
    const sortableTree =  (
        <>
            <SortableTree
                treeData={treeData}
                onChange={(data) => setTreeData(data)}
                expanded={[0]}
                canDrag={false}
                generateNodeProps={({ node, path }) => ({
                    title: (
                        <>
                            <p>
                                <div style={{color:'black'}}>
                                   {console.log('node and path for sortable tree', node, path)}
                                   {node.name}
                                </div>
                                


                            </p>
                            {/* <Button appearance="outline" shape={'Round'} size={'Small'} size='mini' Status={'Info'} circular icon='add' onClick={(e) => { e.preventDefault(); e.stopPropagation(); insertNewNode(path) }}> Add Son </Button>
                                  <Button appearance="outline" shape={'Round'} size={'Small'} size='mini' Status={'Info'} circular icon='trash' onClick={(e) => { e.preventDefault(); e.stopPropagation(); removeNode(path) }} > Add Daughter </Button> */}
                        </>
                    ),
                })}
            />


        </>
    )

    return <><br />{isLoading ? <LoadingSpinner message={'Loading Data...'} /> :sortableTree}</>
}

export default tree;
