import React, { useEffect, useState } from "react";
import LoadingSpinner from '../loader';
import SortableTree, { toggleExpandedForAll, getFlatDataFromTree } from 'react-sortable-tree';
import { Button } from '@paljs/ui/Button';
import { relativeFamilyDetailsByNodeId } from "../../actions/familyTreeAction";
import { Height } from "@material-ui/icons";
import { useLocation } from '@reach/router';
import Select from '@paljs/ui/Select';
import styled from 'styled-components';
import Tree from '../../components/sortable-tree/simple-showcase-tree'
import { userDetail } from "../../actions/templateAction";
import { Accordion, AccordionItem } from '@paljs/ui/Accordion';


const SelectStyled = styled(Select)`
  width: 10rem;
`;

const familyDetails = () => {
  const [paternalTreeData, setPaternalTreeData] = useState([]);
  const [user, setUser] = useState('')
  const [maternalTreeData, setMaternalTreeData] = useState([]);
  const [spouseTreeData, setSpouseTreeData] = useState([]);
  const [paternalLoading, setPaternalLoader] = useState(false);
  const [maternalLoading, setMaternalLoader] = useState(false);
  const [spouseLoading, setSpouseLoader] = useState(false);
  const [maternalDegree, setMaternalDegree] = useState({ label: '1', value: '1' });
  const [spouseDegree, setSpouseDegree] = useState({ label: '1', value: '1' });
  const [paternalDegree, setPaternalDegree] = useState({ label: '1', value: '1' });
  const [paternalLoadingMessage, setPaternalLoadingMessage] = useState('get paternal family');
  const [maternalLoadingMessage, setMaternalLoadingMessage] = useState('get maternal family');
  const [spouseLoadingMessage, setSpouseLoadingMessage] = useState('get spouse family');



  const statusOption: { value: any; label: any }[] = [
    { label: '1', value: '1' },
    { label: '2', value: '2' },
    { label: '3', value: '3' },
  ];

  const location = useLocation();
  const url = decodeURI(location.search);
  const params = new URLSearchParams(url);
  const nodeIdFromUrl = params.get('node_id');
  useEffect(() => {
    userProfile();
  }, [])

  const userProfile = async () => {
    const response = await userDetail(nodeIdFromUrl);
    console.log('user profile', response);
    const name = response?.data?.user?.name + ' ' + response?.data?.user?.gautra
    setUser(name)
  }

  const getPaternalNodeTreeData = async (degree = paternalDegree.value) => {
    setPaternalLoadingMessage("Loading...")
    setPaternalLoader(true);
    const response = await relativeFamilyDetailsByNodeId(nodeIdFromUrl, 'PATERNAL_FAMILY', degree);
    console.log('nodeTreeData, paternal ', response)
    if (response?.status) {
      const data = changeStructure(response?.tree)
      setPaternalTreeData(toggleExpandedForAll({
        treeData: [data],
        expanded: true
      }));
      setPaternalLoadingMessage("get paternal family")
    }else{
      setPaternalLoadingMessage("error occurred")
    }
    setPaternalLoader(false);
  }
  const getMaternalNodeTreeData = async (degree = maternalDegree.value) => {
    setMaternalLoadingMessage('Loading...')
    setMaternalLoader(true)
    const response = await relativeFamilyDetailsByNodeId(nodeIdFromUrl, 'MATERNAL_FAMILY', degree);
    console.log('nodeTreeData, maternal ', response)
    //const data = [{...response?.tree}]
    if (response?.status) {
      const data = changeStructure(response?.tree)
      setMaternalTreeData(toggleExpandedForAll({
        treeData: [data],
        expanded: true
      }));
      setMaternalLoadingMessage("get paternal family")
    }else{
      setMaternalLoadingMessage('error occurred')
    }
    setMaternalLoader(false);
  }
  const getSpouseNodeTreeData = async (degree = spouseDegree.value) => {
    setSpouseLoadingMessage('Loading...')
    setSpouseLoader(true);
    const response = await relativeFamilyDetailsByNodeId(nodeIdFromUrl, 'IN_LAWS_FAMILY', degree);
    console.log('nodeTreeData, in-laws ', response)
    //const data = [{...response?.tree}]
    if (response?.status) {
      const data = changeStructure(response?.tree)
      setSpouseLoader(false);
      setSpouseTreeData(toggleExpandedForAll({
        treeData: [data],
        expanded: true
      }));
      setSpouseLoadingMessage("get spouse family")
    }else{
      setSpouseLoadingMessage('error occurred')
    }
    setSpouseLoader(false);
  }

  function changeStructure(obj) {
    let key = Object.keys(obj)[0];
    if (typeof obj == "string") {
      return { name: obj }
    }
    return {
      name: key,
      children: obj[key].children.map(x => changeStructure(x))
    }
  }

  return (
    <>
      <div className="outerComponent" style={{ width: '100%', display: 'flex', justifyContent: 'space-between', backgroundColor: '#00BFFF', height: '50px' }}>
        <div id="left" style={{ padding: '14px', color: 'white', fontWeight: 'bold', fontSize: '20px' }}>
          श्री {user}
        </div>

      </div>
      <br />
      <Accordion multi>
        <AccordionItem uniqueKey={1} title="Paternal Family" >
          <div style={{ display: 'flex', padding: '6px' }}>
            <div style={{ padding: '6px', fontWeight: 'bold', fontSize: '20px' }}>Degree :</div>
            <SelectStyled options={statusOption} placeholder="Status" onChange={(value) => { getPaternalNodeTreeData(value.value); setPaternalDegree(value) }} value={paternalDegree} />&nbsp;
            <Button onClick={() => { getPaternalNodeTreeData() }}>{paternalLoadingMessage}</Button>
          </div>
          {paternalTreeData.length ? <div style={{ height: '100vh' }}>
            <Tree treeData={paternalTreeData} setTreeData={(data) => { setPaternalTreeData(data) }} isLoading={paternalLoading} />
          </div> : ''}
        </AccordionItem>
        <AccordionItem uniqueKey={2} title="Maternal Family" >
          <div style={{ display: 'flex', padding: '6px' }}>
            <div style={{ padding: '6px', fontWeight: 'bold', fontSize: '20px' }}>Degree :</div>
            <SelectStyled options={statusOption} placeholder="Status" onChange={(value) => { getMaternalNodeTreeData(value.value); setMaternalDegree(value) }} value={maternalDegree} />&nbsp;
            <Button size="Small" onClick={() => { getMaternalNodeTreeData() }}>{maternalLoadingMessage}</Button>
          </div>
          {maternalTreeData.length ? <div style={{ height: '100vh' }}>
            <Tree treeData={maternalTreeData} setTreeData={(data) => { setMaternalTreeData(data) }} isLoading={maternalLoading} />
          </div> : ''}
        </AccordionItem>
        <AccordionItem uniqueKey={3} title="Spouse Family" >
          <div style={{ display: 'flex', padding: '6px' }}>
            <div style={{ padding: '6px', fontWeight: 'bold', fontSize: '20px' }}>Degree :</div>
            <SelectStyled options={statusOption} placeholder="Status" onChange={(value) => { getSpouseNodeTreeData(value.value); setSpouseDegree(value) }} value={spouseDegree} />&nbsp;
            <Button onClick={() => { getSpouseNodeTreeData() }}>{spouseLoadingMessage}</Button>
          </div>
          {spouseTreeData.length ? <div style={{ height: '100vh' }}>
            <Tree treeData={spouseTreeData} setTreeData={(data) => { setSpouseTreeData(data) }} isLoading={spouseLoading} />
          </div> : ''}
        </AccordionItem>
      </Accordion>
    </>
  )
}

export default familyDetails;
